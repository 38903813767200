<template>
	<div class="">
		<el-dialog
			v-loading="listLoading"
			:visible.sync="isActiveOrderDetailDialog"
			:width="width+'px'"
			:style="cssVars"
			append-to-body
			inline
			:before-close="handleCancel">
			
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">
				<span>订单详情</span></span>
			<div style="margin-bottom: 10px"></div>
			
			<div style="width: 100%; overflow-y: scroll; height: 720px" class="scrollBox">
				
				<div class="required"></div>
				<span>收货人信息</span>
				<div style="margin-bottom: 10px"></div>
				
				<div class="customer-info">
					
					<div class="customer-item">
						<div class="item-title">收货人员：</div>
						<div class="item-content">{{orderDetail.shr_name}}</div>
					</div>
					
					<div class="customer-item">
						<div class="item-title">手机号码：</div>
						<div class="item-content">{{orderDetail.mobile}}</div>
					</div>
					
					<div class="customer-item2">
						<div class="item-title">收货地址：</div>
						<div class="item-content">{{orderDetail.region_name}}{{orderDetail.shr_address}}</div>
					</div>
					
					<!--		<div class="customer-item">
								<div class="item-title"></div>
								<div class="item-content"></div>
							</div>-->
				
				</div>
				
				<div class="customer-info">
					
					<div class="customer-item">
						<div class="item-title">建档日期：</div>
						<div class="item-content">{{order.create_con_at}}</div>
					</div>
					
					<div class="customer-item">
						<div class="item-title">客户状态：</div>
						<div class="item-content">{{order.contract_status}}</div>
					</div>
					
					<div class="customer-item">
						<div class="item-title">客户来源：</div>
						<div class="item-content">{{order.from}}</div>
					</div>
					
					<div class="customer-item">
						<div class="item-title">购买次数：</div>
						<div class="item-content">{{order.order_count}}</div>
					</div>
				
				</div>
				<div style="margin-bottom: 20px"></div>
				
				
				<div style="display: flex; justify-content: space-between">
					<div>
						<div class="required"></div>
						<span>订单商品 <span style="margin-left: 20px">线上金额: {{order.online_amount}}</span></span>
						<div style="margin-bottom: 10px"></div>
					</div>
					<div style="display: flex; justify-content: space-between; width: 500px">
						<div style="display: flex;">
							<div class="item-title">预收金额：</div>
							<div class="item-content">{{orderDetail.earn_amount}}</div>
						</div>
						<div style="display: flex;">
							<div class="item-title">支付方式：</div>
							<div class="item-content">{{orderDetail.earn_status == 1 ? "微信已收" : "小程序"}}</div>
						</div>
					</div>
				</div>
				
				<!----------------表格---------------->
				
				<el-table
					border
					v-loading="listLoading"
					:header-cell-style="{'text-align':'center'}"
					:data="tableData"
					style="width: 100%;z-index:0">
					<el-table-column
						label="序号"
						type="index"
						align="center"
						width="50">
					</el-table-column>
					<el-table-column
						prop="id"
						label="类别"
						align="center">
					</el-table-column>
					<el-table-column
						prop="goods_name"
						label="名称"
						align="center">
					</el-table-column>
					<el-table-column
						prop="spec_name"
						label="规格名称"
						align="center">
					</el-table-column>
					<el-table-column
						prop="goods_price"
						label="单价"
						align="center">
					</el-table-column>
					<el-table-column
						prop="number"
						label="购买"
						align="center">
					</el-table-column>
					
					<el-table-column
						prop="use_day"
						label="可用"
						align="center">
					</el-table-column>
					<el-table-column
						prop="expired_at"
						label="过期日期"
						align="center">
					</el-table-column>
					
					<el-table-column
						prop="discount"
						label="优惠"
						align="center">
					</el-table-column>
					
					<el-table-column
						prop="real_amount"
						label="应收金额"
						align="center">
					</el-table-column>
				
				</el-table>
				
				<!----------------分页---------------->
				<!--<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"-->
				<!--						@pagination="getList"/>-->
				
				
				<div style="margin-bottom: 30px"></div>
				
				<div style="display: flex; justify-content: space-between">
					<div>
						<div class="required"></div>
						<span>订单信息 </span>
						<div style="margin-bottom: 10px"></div>
					</div>
					
					<div style="display: flex; justify-content: space-between; width: 500px">
						<div style="display: flex; margin-left: auto ; margin-right: 30px">
							<div class="item-title" style="color: #2632fb; cursor: pointer" @click="handleEditOrder">编辑</div>
						</div>
					</div>
				</div>
				
				
				<div class="customer-info">
					
					
					<div class="customer-item">
						<div class="item-title">订单金额：</div>
						<div class="item-content">{{orderDetail.total_amount}}元</div>
					
					</div>
					
					<div class="customer-item">
						<div class="item-title">订单编号：</div>
						<div class="item-content" style="padding-top: 2px">{{orderDetail.order_sn}}</div>
					</div>
					
					<div class="customer-item">
						<div class="item-title">下单人员：</div>
						<div class="item-content">{{orderDetail.settle_name}}</div>
					</div>
				
				</div>
				
				<div class="customer-info">
					
					<div class="customer-item">
						<div class="item-title">支付方式：</div>
						<div class="item-content">{{orderDetail.earn_status == 1 ? "微信已收" : "小程序"}}</div>
					</div>
					
					<div class="customer-item">
						<div class="item-title">订单状态：</div>
						<div class="item-content">{{orderDetail.order_status_name}}</div>
					</div>
					
					<div class="customer-item">
						<div class="item-title">下单时间：</div>
						<div class="item-content" style="padding-top: 2px">{{orderDetail.created_at}}</div>
					</div>
				
				</div>
				
				
				<div class="customer-info">
					
					<div class="customer-item">
						<div class="item-title">预付金额：</div>
						<div class="item-content">{{orderDetail.earn_amount}}元</div>
					
					</div>
					
					<!--<div class="customer-item">-->
					<!--	<div class="item-title">过期时间：</div>-->
					<!--	<div class="item-content">{{orderDetail.expired_at}}</div>-->
					<!--</div>-->
					
					<div class="customer-item">
						<div class="item-title">到期日期：</div>
						<div class="item-content" style="padding-top: 2px">{{orderDetail.expired_at}}</div>
					</div>
					
					<div class="customer-item">
						<div class="item-title">销售说明：</div>
						<div class="item-content">{{orderDetail.own_remark}}</div>
					</div>
				
				</div>
				
				
				<div class="customer-info">
					
					<div class="customer-item">
						<div class="item-title">优惠金额：</div>
						<div class="item-content">{{orderDetail.discount_amount}}元</div>
					</div>
					<div class="customer-item">
						<div class="item-title">审核状态：</div>
						<div class="item-content">{{order.examine_at == '' ? "待审核" : "已审核"}}</div>
					</div>
					
					
					<div class="customer-item">
						<div class="item-title"></div>
						<div class="item-content"></div>
					</div>
				
				</div>
				
				
				<div class="customer-info">
					
					<div class="customer-item">
						<div class="item-title">应收金额：</div>
						<div class="item-content">{{orderDetail.actual_amount}}元</div>
						<!--<div class="item-title">预付金额：</div>
						<div class="item-content">{{orderDetail.earn_amount}}元</div>-->
					</div>
					<div class="customer-item">
						<div class="item-title">审核日期：</div>
						<div class="item-content" style="padding-top: 2px">{{order.examine_at}}</div>
					</div>
					
					
					<div class="customer-item">
						<div class="item-title"></div>
						<div class="item-content"></div>
					</div>
				
				</div>
				
				
				<div class="customer-info">
					
					<div class="customer-item">
						<div class="item-title">待收金额：</div>
						<div class="item-content">{{orderDetail.cash_amount}}元</div>
					</div>
					
					<div class="customer-item">
						<div class="item-title">发货时间：</div>
						<div class="item-content" style="padding-top: 2px">{{order.send_time}}</div>
					</div>
					
					<!--<div class="customer-item">-->
					<!--	<div class="item-title">应收金额：</div>-->
					<!--	<div class="item-content">{{orderDetail.actual_amount}}元</div>-->
					<!--</div>-->
					
					<div class="customer-item">
						<div class="item-title"></div>
						<div class="item-content"></div>
					</div>
				
				</div>
				
				<div style="margin-bottom: 30px"></div>
				
				
				<div style="display: flex; justify-content:start">
					<div>
						<div class="required"></div>
						<span>物流反馈</span>
					</div>
					<div style="display: flex;margin-left: 30px">
						<div>{{orderDetail.express_info && orderDetail.express_info.express_company}}：</div>
						<div style="margin-top: 2px">{{orderDetail.express_info && orderDetail.express_info.express_no}}</div>
					</div>
				</div>
				
				<div style="margin-bottom: 10px"></div>
				
				<el-timeline :reverse="reverse">
					<el-timeline-item
						v-for="(activity, index) in activities"
						:key="index"
						:timestamp="activity.time">
						{{activity.status}}
					</el-timeline-item>
				</el-timeline>
				
				
				<div style="margin-bottom: 30px"></div>
			
			
			</div>
		
		</el-dialog>
		
		<!--编辑订单-->
		<!--传入宽度改变弹出框大小-->
		<dialogEditOrder :isActiveDialog.sync="isActiveDialog"
										 @getList="getList"
										 :info="info"
										 v-if="isActiveDialog"
										 width="640"
		></dialogEditOrder>
	
	</div>
</template>

<script>
import dialogEditOrder from './dialogEditOrder';
import {getOrderDetail} from '@/api/ggkq';

export default {
  name: "DialogOrderDetail",
  components: {
    dialogEditOrder
  },
  props: {
    width: { // 弹出框的宽度配置
      type: String,
      default: '640',
    },
    isActiveOrderDetailDialog: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: String,
      default: '',
      required: true,
    },
    order: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    }
  },
  computed: {
    cssVars () {
      return {
        "--widthToOne": this.width - 50 + "px",
        "--widthToTwo": this.width / 2 - 40 + "px",
      }
    }
  },
  async created () {
    await this.handleGetOrderDetail()
  },
  data () {
    return {
      searchParams: {},
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      orderDetail: {},
      tableData: [],
      reverse: true,
      activities: [{
        content: '活动按期开始',
        timestamp: '2018-04-15'
      }, {
        content: '通过审核',
        timestamp: '2018-04-13'
      }, {
        content: '创建成功',
        timestamp: '2018-04-11'
      }],
      isActiveDialog: false,
      info: {},
    }
  },
  methods: {
    async handleGetOrderDetail () {
      var params = {
        order_id: this.orderId
      }
      var result = await getOrderDetail(params)
      console.log(result)
      this.tableData = result.data.goods
      if ( Object.keys(result.data.express_info).length > 0  && result.data.express_info.logs.length > 0) {

        this.activities = result.data.express_info.logs.reverse()
      }
      this.orderDetail = result.data
    },
    test () {
    },
    getList (type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      console.log('getList触发')
      //
      // this.listLoading = true
      // this.searchParams.page = this.listQuery.page
      // this.searchParams.page_size = this.listQuery.limit
      // this.test(this.searchParams).then(response => {
      //   let data = response
      //   if (data.code == 200) {
      //     let _data = data.data
      //     this.total = _data.total
      //     this.listQuery.limit = _data.per_page
      //     this.tableData = _data.list
      //   }
      //   this.listLoading = false
      // }).catch(error => {
      //   console.log(error);
      //   this.listLoading = false
      // })
    },
    handleCancel (e) {
      this.$emit('update:isActiveOrderDetailDialog', false)
    },
    handleEditOrder () {
      this.isActiveDialog = true
      this.info.discount_amount = this.orderDetail.discount_amount
      this.info.earn_amount = this.orderDetail.earn_amount
      this.info.examine_at = this.order.examine_at
      this.info.send_time = this.order.send_time
      this.info.orderId = this.orderId
    }

  }
}
</script>

<style scoped lang="scss">
	/*竖杠+后面文本的样式*/
	.required {
		margin-top: 2px;
		display: inline-block;
		width: 4px;
		height: 14px;
		background-color: #2362FB;
		top: 20px;
	}
	
	.required + span {
		display: inline-block;
		margin-left: 4px;
		vertical-align: top;
		font-weight: bold;
		font-size: 14px;
	}
	
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	
	/*弹出框表单配置*/
	/deep/ .el-form-item__label {
		padding-bottom: 0;
	}
	
	/deep/ .el-form-item__content {
		width: var(--widthToTwo);
	}
	
	.from-item-second {
		margin-left: 0;
	}
	
	/deep/ .from-item-third {
		.el-form-item__content {
			width: var(--widthToOne);
		}
		
		.el-select {
			width: var(--widthToOne) !important;
		}
	}
	
	/deep/ .el-cascader {
		width: var(--widthToTwo);
	}
	
	/deep/ .el-select {
		width: var(--widthToTwo) !important;
	}
	
	/deep/ .el-date-editor {
		width: var(--widthToTwo) !important;
	}
	
	/*select 宽度配置*/
	/deep/ .el-select {
		width: 85px;
	}
	
	
	.customer-info {
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		
		.customer-item {
			flex: 1;
			width: 200px;
			display: flex;
			
			.item-title {
				font-size: 14px;
				font-weight: bolder;
				margin-right: 10px;
				color: #333;
			}
			
			.item-content {
				font-size: 14px;
				color: #333;
			}
		}
		
		.customer-item2 {
			flex: 2;
			width: 200px;
			display: flex;
			
			.item-title {
				flex-shrink: 0;
				font-size: 14px;
				font-weight: bolder;
				margin-right: 10px;
				color: #333;
			}
			
			.item-content {
				font-size: 14px;
				color: #333;
			}
		}
	}
	
	.item-title {
		flex-shrink: 0;
		font-size: 14px;
		font-weight: bolder;
		margin-right: 10px;
		color: #333;
	}
	
	.item-content {
		font-size: 14px;
		color: #333;
	}
	
	.line {
		margin-bottom: 20px;
		width: 100%;
		height: 1px;
		background-color: #eaeaea;
	}
	
	
	.scrollBox::-webkit-scrollbar {
		width: 5px;
	}
	
	.scrollBox::-webkit-scrollbar-thumb {
		border-radius: 20px;
		-webkit-box-shadow: inset 0 0 5px #fff;
		background: #D8D8D8;
	}
	
	.scrollBox::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 5px #fff;
		border-radius: 0;
		background: #fff;
	}

</style>
