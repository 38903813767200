<template>
	<div class="">
		<el-dialog
			v-loading="listLoading"
			:visible.sync="isActiveDialog"
			:width="width+'px'"
			:style="cssVars"
			inline
			:before-close="handleCancel">
			<span slot="title" style="font-size: 14px;color: #606266;font-weight: 700;">编辑订单信息</span>
			
			<el-form :model="ruleForm" :rules="rules" size="mini" :inline="true" label-position="top" ref="ruleForm">
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="优惠金额" prop="discount_amount" class="from-item-second">
						<el-input v-model.number.trim="ruleForm.discount_amount" autocomplete="off" clearable placeholder="testInput"></el-input>
					</el-form-item>
					
					<el-form-item label="审核时间" prop="examine_at" class="from-item-second">
						<el-date-picker
							v-model="ruleForm.examine_at"
							type="datetime"
							placeholder="选择日期时间">
						</el-date-picker>
					</el-form-item>
				</div>
				
				<!--一行2个选项两端对齐-->
				<div style="display: flex; justify-content: space-between">
					<el-form-item label="预付金额" prop="earn_amount" class="from-item-second">
						<el-input v-model.trim="ruleForm.earn_amount" autocomplete="off" clearable placeholder="testInput"></el-input>
					</el-form-item>
					
					<el-form-item label="发货时间" prop="send_time" class="from-item-second">
						<el-date-picker
							v-model="ruleForm.send_time"
							type="datetime"
							placeholder="选择日期时间">
						</el-date-picker>
					</el-form-item>
				</div>
				
			</el-form>
			
			<div slot="footer" class="dialog-footer">
				<el-button class="mgr-20" size="mini" @click="handleCancel">取 消</el-button>
				<el-button size="mini" type="primary" @click="handleConfirm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {postEditOrder} from '@/api/ggkq';

export default {
  name: "dialogOneTest",
  components: {
  
  },
  props: {
    width: { // 弹出框的宽度配置
      type: String,
			default: '640',
		},
    isActiveDialog: {
      type: Boolean,
      default: false
    },
		info: {
      type: Object,
			required: true,
			default: () => {return {}}
		}
  },
	computed: {
    cssVars() {
      return {
        "--widthToOne" : this.width - 50 + "px",
        "--widthToTwo" : this.width / 2 - 40+ "px",
			}
		}
	},
	created () {
    this.ruleForm.discount_amount = this.info.discount_amount
    this.ruleForm.examine_at = this.info.examine_at
    this.ruleForm.earn_amount = this.info.earn_amount
    this.ruleForm.send_time = this.info.send_time
  },
  data () {
    return {
      listLoading: false,
      url: [],
      options: [{
        value: 'test',
        id: '1'
      }],
      ruleForm: {
        discount_amount: '',
        examine_at: '',
        earn_amount: '',
        send_time: ''
      },
      rules: {
        discount_amount: [
          { required: true, message: '优惠金额', trigger: 'blur' }
        ],
        examine_at: [
          { required: true, message: '审核时间', trigger: 'blur' }
        ],
        earn_amount: [
          { required: true, message: '预付金额', trigger: 'blur' }
        ],
        send_time: [
          { required: true, message: '发货时间', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    handleSelect () {

    },
    handleConfirm (ruleForm) {
      this.submitForm(ruleForm)
    },
    handleCancel (e) {
      this.resetForm('ruleForm')
      this.$emit('update:isActiveDialog', false)
    },
    submitForm (formName) {
      console.log(this.$refs[formName])
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // 出发test页面更新列表
					this.ruleForm.order_id = this.info.orderId
					var result = await postEditOrder(this.ruleForm)
					if (result.code == 200) {
            this.$message({
              type: 'success',
              message: '修改成功!'
            })
					} else {
            this.$message({
              type: 'error',
              message: result.msg
            })
					  return ;
					}
					console.log("123123abc")
          this.$emit('getList')
          this.$emit('update:isActiveDialog', false)
        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
  }
}
</script>

<style scoped lang="scss">
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	/*dialog对话框样式调整*/
	/deep/ .el-dialog__body {
		padding-bottom: 0 !important;
	}
	
	/deep/ .el-dialog__footer {
		background-color: #fafafa;
		border-top: 1px solid #eaeaea;
		padding: 10px 30px 10px 0;
	}
	
	
	/*弹出框表单配置*/
	/deep/ .el-form-item__label {
		padding-bottom: 0;
	}
	
	/deep/ .el-form-item__content {
		width: var(--widthToTwo);
	}
	
	.from-item-second {
		margin-left: 0;
	}
	
	/deep/ .from-item-third {
		.el-form-item__content {
			width: var(--widthToOne);
		}
		.el-select{
			width: var(--widthToOne) !important;
		}
	}
	
	/deep/ .el-cascader {
		width: var(--widthToTwo);
	}
	
	/deep/ .el-select {
		width: var(--widthToTwo) !important;
	}
	
	/deep/ .el-date-editor {
		width: var(--widthToTwo) !important;
	}
	
	/*select 宽度配置*/
	/deep/ .el-select {
		width: 85px;
	}
</style>
