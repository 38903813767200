<template>
	<div class=".gg-container-1">
		
		<div class="search-container">
			
			<div class="search-container-fn-input">
				<label>收货人：</label>
				<el-input
					style="width:160px"
					size="mini"
					placeholder="请输入收货人姓名/手机"
					prefix-icon="el-icon-search"
					v-model="searchParams.keywords"
					clearable
					@change="getList('restPage')"
				>
				</el-input>
			</div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="getList('restPage')"
			>
				<span>查找</span>
			</el-button>
			<!--<div v-for="(item,index) in orderStatusOptions" :key="item.label">
				<div :class="{active: item.value == curOrderStatus, 'order-box-first' : 0 == index,  'order-box': 0 != index}"
						 @click="handleOrderStatus(item)">{{item.label}}
				</div>
			</div>-->
			
			<div style="margin-left: auto; margin-right: 30px; color: #2632fb; font-size: 14px">
				金额汇总: {{priceTotal}}
			</div>
			
			<!--按钮样式-->
			<div style="margin-left: auto; display: flex; justify-content: space-around">
				
				<div class="search-container-fn-input"
						 v-if="operateList.indexOf('update_table') > -1"
				>
					<el-button size="mini" type="primary" icon="el-icon-edit-outline"
										 style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
										 @click="handleEditTableHead">编辑表头
					</el-button>
				</div>
				
				<div class="search-container-fn-input"
						 v-if="operateList.indexOf('reset_query') > -1"
				>
					<el-button size="mini" type="primary" icon="el-icon-refresh"
										 style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
										 @click="handleResetSearch">重置查找
					</el-button>
				</div>
				
				<div class="search-container-fn-input"
						 v-if="operateList.indexOf('mul_examine') > -1"
				>
					<el-button
						size="mini"
						type="primary"
						style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
						@click="handleBatchCheckOrder">
						<i class="el-icon-plus" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">批量审批</span>
					</el-button>
				</div>
				
				<!--对应第三方图标的button样式-->
				<div class="search-container-fn-input"
						 v-if="operateList.indexOf('export') > -1"
				>
					<el-button
						size="mini"
						type="success"
						style="padding: 6px 7px 7px 8px"
						@click="handleExportOrder">
						<i class="iconfont icon-shangchuan" style="font-size: 13px"/>
						<span style="margin-left: 5px;font-size: 12px">导出订单</span>
					</el-button>
				</div>
			</div>
		</div>
		
		<!--<div class="search-container">
			
			<div class="search-container-fn-input">
				<label>下单时间：</label>
				<el-date-picker
					v-model="choiceDate"
					type="datetimerange"
					size="small"
					value-format="yyyy-MM-dd HH:mm:ss "
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="handleChangeDate">
				</el-date-picker>
			</div>
			
				<div class="search-container-fn-input">
				<label>客户来源：</label>
				<el-select
					style="width:200px"
					v-model="searchParams.from_id"
					placeholder="请输入客户来源"
					size="small"
					clearable
					@change="getList('restPage')"
				>
					<template slot="prefix">
						<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
					</template>
					<el-option
						v-for="item in customerFromOptions"
						:key="item.id"
						:label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
			</div>
			
		</div>-->
		
		<!--<div class="search-container">
			&lt;!&ndash;输入框&ndash;&gt;
			<div class="search-container-fn-input">
				<label>购买次数：</label>
				<el-input
					style="width:200px"
					size="small"
					placeholder="购买次数"
					prefix-icon="el-icon-search"
					v-model="searchParams.buy_count"
					clearable
					@change="getList('restPage')"
				>
				</el-input>
			</div>
			
			<div class="search-container-fn-input">
				<label>下单人员：</label>
				<el-select
					style="width:200px"
					v-model="searchParams.staff_id"
					placeholder="下单人员"
					size="small"
					clearable
					@change="getList('restPage')"
				>
					<template slot="prefix">
						<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
					</template>
					<el-option
						v-for="item in staffOptions"
						:key="item.id"
						:label="item.staff_name"
						:value="item.id">
					</el-option>
				</el-select>
			</div>
			
			<div class="search-container-fn-input">
				<label>客户状态：</label>
				<el-cascader
					clearable
					:options="customerStatusOptions"
					size="small"
					placeholder="请选择客户状态"
					:props="{ expandTrigger: 'hover' }"
					@change="handleChangeCustomerStatus">
				</el-cascader>
			</div>
			<div class="search-container-fn-input">
				<label>建档时间：</label>
				<el-date-picker
					v-model="choiceDateCreate"
					type="datetimerange"
					size="small"
					value-format="yyyy-MM-dd HH:mm:ss "
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="handleChangeDateCreate">
				</el-date-picker>
			</div>
			
			
		
		</div>-->
		
	<!--	<div class="search-container">
			<div class="search-container-fn-input">
				<label>签收时间：</label>
				<el-date-picker
					v-model="choiceDateSign"
					type="datetimerange"
					size="small"
					value-format="yyyy-MM-dd HH:mm:ss "
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					@change="handleChangeDateSign">
				</el-date-picker>
			</div>
		</div>-->
		
		<!----------------表格---------------->
		<!--<el-table
			border
			ref="multipleTable"
			v-loading="listLoading"
			:header-cell-style="{'text-align':'center'}"
			:data="tableData"
			height="625"
			@selection-change="handleSelectionChange"
			style="width: 100%;z-index:0">
			<el-table-column
				type="selection"
				:selectable="selectInit"
				align="center"
				width="55">
			</el-table-column>
			<el-table-column
				label="下单时间"
				prop="created_at"
				align="center"
				width="180">
			</el-table-column>
			<el-table-column
				label="订单编号"
				prop="order_sn"
				align="center"
				width="220">
				<template slot-scope="scope">
					<a style="color: #2632fb" @click="handleOrderDetail(scope.row)">{{scope.row.order_sn}}</a>
				</template>
			</el-table-column>
			<el-table-column
				prop="online_amount"
				label="线上金额"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="actual_amount"
				label="订单金额"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="use_day"
				label="可用天数"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="order_status_name"
				label="订单状态"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="staff_name"
				label="下单人员"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="shr_name"
				label="客户姓名"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="from"
				label="客户来源"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="create_con_at"
				label="建档日期"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="contract_status"
				label="客户状态"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="examine_at"
				label="审核状态"
				align="center"
				width="120">
				<template slot-scope="scope">
					{{scope.row.examine_at == '' ? '未审核' : '已审核'}}
				</template>
			</el-table-column>
			<el-table-column
				prop="examine_at"
				label="审核日期"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="send_time"
				label="发货时间"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="sign_at"
				label="签收时间"
				align="center"
				width="120">
			</el-table-column>
			<el-table-column
				prop="order_count"
				label="购买次数"
				align="center"
				width="120">
			</el-table-column>
			&lt;!&ndash;<el-table-column align="center" width="200" label="操作" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="primary" icon="el-icon-edit"
										 style="margin-right: 10px; padding: 7px 8px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
										 @click="handleTable01(scope.$index, scope.row)">编辑
					</el-button>
					<el-button size="mini" type="danger" icon="el-icon-delete"
										 style="padding: 7px 8px;margin-left: 5px;"
										 @click="handleTable02(scope.$index, scope.row)">删除
					</el-button>
				</template>
			</el-table-column>&ndash;&gt;
		</el-table>-->
		
		<el-table
			border
			ref="multipleTable"
			size="mini"
			v-loading="listLoading"
			:header-cell-style="{'text-align':'center'}"
			:data="tableData"
			height="625"
			@selection-change="handleSelectionChange"
			style="width: 100%;z-index:0">
			<el-table-column
				type="index"
				align="center"
				width="50">
			</el-table-column>
			<template
				v-for="(column,index) in tableHead"
			>
				<el-table-column
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-if="column.field_type === 'textBtn' && column.visible === true "
					show-overflow-tooltip
				>
					<template #header>
						<el-popover placement="bottom" title="" width="230" trigger="hover">
							<div slot="reference" class="search-header">
								<span class="search-title">{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
									 class="el-icon-search"
									 :class="{isSearch: searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch,
									  'el-icon-zoom-in': searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch}"
								/>
							</div>
							<el-input
									size="mini"
									placeholder="订单编号"
									prefix-icon="el-icon-search"
									v-model="searchParams.order_sn"
									clearable
									@change="handleSearch(column.column_prop ,searchParams.order_sn)"
								>
								</el-input>
						</el-popover>
					</template>
					<template slot-scope="scope">
						<a style="font-size: 12px; color: #2379fb"
							 @click.prevent="handleOrderDetail(scope.row)">
							{{scope.row[column.column_prop]}}
						</a>
					</template>
				</el-table-column>
				
				<!--<el-table-column
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-else-if="column.field_type === 'imageBtn' && column.visible === true "
					show-overflow-tooltip
				>
					<template slot-scope="scope">
						<div v-if="scope.row[column.column_prop].length>0" style="font-size: 12px; color: #2379fb"
								 @click="handleOrderDetail(scope.row[column.column_prop],  scope.row[column.column_prop][0])">
							<a>点击查看图片</a>
							<el-image
								style="width: 0px; height: 0px"
								:ref="scope.row[column.column_prop][0]"
								:src="imageUrl"
								:preview-src-list="scope.row[column.column_prop]"
								:z-index="9999">
							</el-image>
						</div>
						<div v-else>
							暂无
						</div>
					</template>
				</el-table-column>-->
				
				<el-table-column
					:sortable="tableHeadSortable.includes(column.column_prop)"
					:prop="column.column_prop"
					:label="column.column_label_user_definition ? column.column_label_user_definition : column.column_label"
					:width="column.width ? column.width : '' "
					:key="index"
					align="center"
					v-else-if="column.visible === true"
					show-overflow-tooltip
				>
					<template #header>
						<el-popover placement="bottom" title="" min-width="160" trigger="click" v-if = "searchTableHead.filter( item => item.name == column.column_prop).length > 0" >
							<span slot="reference" class="search-header" >
								<span
									class="search-title"
								>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
								<i style="margin-left: 3px;font-weight: bolder; font-size: 20px"
									 class="el-icon-search"
									 :class="{isSearch: searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch,
									  'el-icon-zoom-in': searchTableHead.filter(item => item.name == column.column_prop)[0].isSearch}"
								/>
							</span>
							<el-input
								v-if="'order_count' == column.column_prop"
								size="mini"
								placeholder=""
								prefix-icon="el-icon-search"
								v-model="searchParams.buy_count"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.buy_count)"
							>
							</el-input>
							
							<el-input
								v-if="'age' == column.column_prop"
								size="mini"
								placeholder=""
								prefix-icon="el-icon-search"
								v-model="searchParams.age"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.age)"
							>
							</el-input>
							
							
							<el-select
								v-else-if="'from' == column.column_prop"
								style="width:200px"
								v-model="searchParams.from_id"
								placeholder="请选择"
								size="mini"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.from_id)"
							>
								<template slot="prefix">
									<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
								</template>
								<el-option
									v-for="item in customerFromOptions"
									:key="item.id"
									:label="item.name"
									:value="item.id">
								</el-option>
							</el-select>

							<el-select
								v-else-if="'cdm_order_type_name' == column.column_prop"
								style="width:200px"
								v-model="searchParams.cdm_order_type"
								placeholder="请选择"
								size="mini"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.cdm_order_type)"
							>
								<template slot="prefix">
									<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
								</template>
								<el-option
									v-for="item in customerOrderTypeOptions"
									:key="item.value"
									:label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
							
							
							
							<el-select
								v-else-if="'staff_name' == column.column_prop"
								style="width:200px"
								v-model="searchParams.staff_id"
								placeholder="请选择"
								size="mini"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.staff_id)"
							>
								<template slot="prefix">
									<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
								</template>
								<el-option
									v-for="item in staffOptions"
									:key="item.id"
									:label="item.staff_name"
									:value="item.id">
								</el-option>
							</el-select>
							
							<el-select
								v-else-if="'order_status_name' == column.column_prop"
								style="width:200px"
								v-model="searchParams.order_status"
								placeholder="请选择"
								size="mini"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.order_status_name)"
							>
								<template slot="prefix">
									<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
								</template>
								<el-option
									v-for="item in orderStatusOptions"
									:key="item.status"
									:label="item.label"
									:value="item.status">
								</el-option>
							</el-select>
							
							
							
							<el-cascader
								v-else-if="'contract_status' == column.column_prop"
								clearable
								:options="customerStatusOptions"
								size="mini"
								placeholder="请选择客户状态"
								:props="{ expandTrigger: 'hover' }"
								@change="handleChangeCustomerStatus">
							</el-cascader>
							
							<el-input
								v-if="'sign_count' == column.column_prop"
								size="mini"
								placeholder=""
								prefix-icon="el-icon-search"
								v-model="searchParams.sign_count"
								clearable
								@change="handleSearch(column.column_prop ,searchParams.sign_count)"
							>
							</el-input>
							
							
							<el-date-picker
								v-else-if="'created_at' == column.column_prop"
								v-model="choiceDate"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH-mm-ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleChangeDate(column.column_prop)">
							</el-date-picker>
							
							
							<el-date-picker
								v-else-if="'create_con_at' == column.column_prop"
								v-model="choiceDateCreate"
								:default-time="['00:00:00', '23:59:59']"
								type="daterange"
								size="mini"
								value-format="yyyy-MM-dd HH-mm-ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleChangeDateCreate(column.column_prop)">
							</el-date-picker>
							
							<el-date-picker
								v-else-if="'sign_at' == column.column_prop"
								v-model="choiceDateSign"
								type="datetimerange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleChangeDateSign(column.column_prop)">
							</el-date-picker>
							
							
							<el-date-picker
								v-else-if="'examine_at' == column.column_prop"
								v-model="choiceDateExamine"
								type="datetimerange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleChangeDateExamine(column.column_prop)">
							</el-date-picker>
							
							
							<el-date-picker
								v-else-if="'send_time' == column.column_prop"
								v-model="choiceDateSend"
								type="datetimerange"
								size="mini"
								value-format="yyyy-MM-dd HH:mm:ss "
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								@change="handleChangeDateSend(column.column_prop)">
							</el-date-picker>
							
							
								<el-select
									v-else-if="'settle_name' == column.column_prop"
									style="width:200px"
									v-model="searchParams.settle_id"
									placeholder="下单人员"
									size="small"
									clearable
									@change="handleSearch(column.column_prop ,searchParams.settle_id)"
								>
									<template slot="prefix">
										<i class="el-icon-search" style="width: 25px; line-height: 32px;"></i>
									</template>
									<el-option
										v-for="item in staffOptions"
										:key="item.id"
										:label="item.staff_name"
										:value="item.id">
									</el-option>
								</el-select>
					
							
						</el-popover>
						<span v-else>{{column.column_label_user_definition ? column.column_label_user_definition : column.column_label}}</span>
					</template>
					<template slot-scope="scope">
						<span v-if="column.column_prop === 'buy_status'">{{scope.row[column.column_prop] === 1 ? '未购' : '已购' }}</span>
						<span v-else-if="column.column_prop === 'use_status'">
								{{scope.row[column.column_prop] | useStatusFormat }}
							</span>
						<span v-else-if="column.column_prop === 'is_wx'">
								{{scope.row[column.column_prop] == '0' ? '否' : '是'}}
							</span>
						<span v-else-if="column.column_prop === 'supervise_status'">
								{{scope.row[column.column_prop] | formatSuperviseStatus}}
							</span>
						<span v-else>{{scope.row[column.column_prop]}}</span>
					</template>
				</el-table-column>
			</template>
		</el-table>
		
		<!----------------分页---------------->
		<pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit"
								@pagination="getList"/>
		
		<!--查看订单详情弹出框-->
		<dialogOrderDetail :isActiveOrderDetailDialog.sync="isActiveOrderDetailDialog"
											 @getList="getList"
											 :orderId="orderId"
											 :order="order"
											 v-if="isActiveOrderDetailDialog"
											 width="1200"
		></dialogOrderDetail>
	
		<!--编辑表头-->
		<editTableHead :isActiveEditTableHead.sync="isActiveEditTableHead"
															 @_getAdminFieldIndex="_getAdminFieldIndex"
															 v-if="isActiveEditTableHead"
      :table_type="table_type"
    >
		
		</editTableHead>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {mapGetters, mapState} from 'vuex';
import {
  getOrderStatusList,
  getOrderCustomerList,
  postBatchCheckOrder,
  postExportOrder,
  getStaffList,
  getChancelFrom,
  getAdminFieldIndex
} from '@/api/ggkq';
import dialogOrderDetail from './components/dialogOrderDetail';
import editTableHead from '@/components/editTableHead';
export default {
  name: "clerkOrderManager",
  components: {
    Pagination,
    dialogOrderDetail,
    editTableHead
  },
  computed: {
    ...mapGetters(["agentId", "adminId"]),
    ...mapState({
      is_admin: state => state.user.is_admin,
      operates: state => state.user.operates,
	  table_options: state => state.user.table_options,
    }),
  },
  async created () {
    console.log(this.$route.path)
    console.log(this.operates)
    this.operates.forEach(operate => {
      if(operate[this.$route.path]) {
        console.log(operate[this.$route.path].split(','))
        this.operateList.push(...operate[this.$route.path].split(','))
      }
    })
    await this._getAdminFieldIndex()
    this.getTodayTime()
    await this._getOrderStatusList()
    await this._getStaffList()
		await this._getCustomerFrom()
    this.getList()
  },
  data () {
    return {
      table_type:'OrderManager',
	  customerOrderTypeOptions:[
		{label:"导线订单",value:1},
		{label:"生成订单",value:2},
	  ],
      searchTableHead: [
        {
          name: 'order_sn', // ?
          isSearch: false
        },
        {
          name: 'created_at',
          isSearch: true
        },
        {
          name: 'create_con_at',
          isSearch: false
        },
        {
          name: 'sign_at',
          isSearch: false
        },
        {
          name: 'examine_at',
          isSearch: false
        }, {
          name: 'send_time',
          isSearch: false
        },
				
        {
          name: 'from',
          isSearch: false
        },
		{
          name: 'cdm_order_type_name',
          isSearch: false
        },
        {
          name: 'order_count',
          isSearch: false
        },
        {
          name: 'age',
          isSearch: false
        },
        {
          name: 'staff_name',
          isSearch: false
        },
        {
          name: 'contract_status',
          isSearch: false
        },
        {
          name: 'settle_name',
          isSearch: false
        },
        {
          name: 'sign_count',
          isSearch: false
        },
        {
          name: 'order_status_name',
          isSearch: false
        },
      ],
      tableHeadSortable: ["use_day", "online_amount", "actual_amount"],
      tableHead: [],
      isActiveEditTableHead: false,
			
      choiceDate: [],
      choiceDateCreate: [],
      choiceDateSign: [],
      choiceDateExamine: [],
      choiceDateSend: [],
      searchParams: {
        order_sn: '',
        keywords: '',
        order_status: '',
        created_at: "",
				sign_at: ""
      },
      listQuery: {
        page: 1,
        limit: 100,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      options: [],
			customerFromOptions: [],

      customerStatusOptions: [
        {
          value: '',
          label: '初购',
          children: [
            {
              value: '1',
              label: '咨询',
            },
            {
              value: '2',
              label: '追访',
            },
            {
              value: '6',
              label: '全部',
            },
          ],
        },
        {
          value: '',
          label: '复购',
          children: [
            {
              value: '3',
              label: '在用',
            },
            {
              value: '5',
              label: '停用',
            },
            {
              value: '7',
              label: '全部',
            },
          ],
        }
      ],
      // 1=待确认 2=待支付, 3=待发货, 4=待收货, 5=售后中, 7 已完成 8 已取消 9 待审核
      orderStatusOptionsSelect: [
        {
          label:  '待确认',
          value: '1'
        },
        {
          label:  '待支付',
          value: '2'
        },
        {
          label:  '待发货',
          value: '3'
        },
        {
          label:  '待收货',
          value: '4'
        },
        {
          label:  '售后中',
          value: '5'
        },
        {
          label:  '已签收',
          value: '-1'
        },
        {
          label:  '已取消',
          value: '8'
        },
        {
          label:  '待审核',
          value: '9'
        }
      ],
      orderStatusOptions: [
        // 1=待确认2=待支付,3=待发货,4=待收货,5=售后中7 已完成 8 已取消 9待审核
        { value: 'count', label: '订单总数', status: '' },
        { value: 'confirm_count', label: '已确认【待支付】', status: 2 },
        { value: 'un_examine_count', label: '待审核', status: 9 },
        { value: 'un_confirm_count', label: '待确认', status: 1 },
        { value: 'un_deliver_count', label: '待发货', status: 3 },
        { value: 'un_receive_count', label: '待收货', status: 4 },
        { value: 'un_refund_count', label: '售后', status: 5 },
        { value: 'complete_count', label: '已签收', status: -1 },
        { value: 'cancel_count', label: '已取消', status: 8 }
      ],
      staffOptions: [],
      curOrderStatus: 'count',

      tableData: [],
      isActiveOrderDetailDialog: false,
      orderId: '',
      order: {},
      orderIds: [],
      multipleSelection: [],
      priceTotal: '',
      operateList: [],
    }
  },
  methods: {
    async _getAdminFieldIndex () {
    //   try {
    //     this.listLoading = true
    //     var params = {
    //       type: 'OrderManager'
    //     }
    //     const res = await getAdminFieldIndex(params)
        this.tableHead = this.table_options.OrderManager
    //     console.log(this.tableHead)
    //   } catch (err) {
    //     //在此处理错误
    //   } finally {
    //     this.listLoading = false
    //   }

    },

    handleEditTableHead () {
      this.isActiveEditTableHead = true
    },
    handleSearch(prop, value) {
      this.searchTableHead.forEach(item => {
        if (item.name == prop) {
          if (value == '' || value == null || value == undefined) {
            item.isSearch = false
          } else {
            item.isSearch = true
          }
        }
      })
      this.getList('restPage')
    },
    handleResetSearch() {
      this.searchParams = {
        type: 'no_buy',
        is_wx: '',
        visit_level: '',
        buy_count: '',
      }
      this.searchTableHead.forEach(item => {
        item.isSearch = false
      })
      this.choiceDate = []
      this.choiceDateCreate = []
      this.choiceDateSign = []
      this.getList('restPage')
    },
 
    
    
    getTodayTime () {
      var date = new Date();
      // 获取当前月份
      var nowMonth = date.getMonth() + 1;
      // 获取当前是几号
      var strDate = date.getDate();
      // 添加分隔符“-”
      var separator = "-";
      // 对月份进行处理，1-9月在前面添加一个“0”
      if (nowMonth >= 1 && nowMonth <= 9) {
        nowMonth = "0" + nowMonth;
      }
      // 对月份进行处理，1-9号在前面添加一个“0”
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var nowDate = date.getFullYear() + separator + nowMonth + separator + strDate + ' ' + '00:00:00';
      var nowDateLast = date.getFullYear() + separator + nowMonth + separator + strDate + ' ' + '23:59:59';
      this.searchParams.created_at = nowDate + "," + nowDateLast
			this.choiceDate.push(new Date(Date.parse(nowDate)))
      this.choiceDate.push(new Date(Date.parse(nowDateLast)))
		},
    async _getOrderStatusList () {
      
			
      var result = await getOrderStatusList();
      console.log(result)
      Object.keys(result.data).forEach(key => {
        this.orderStatusOptions.forEach(order => {
          if (key == order.value) {
            // order.label += " " + result.data[key]
            order.label
          }
        })
      })
    },

    getList (type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      this.tableData = []
      this.listLoading = true
      this.searchParams.page = this.listQuery.page
      this.searchParams.limit = this.listQuery.limit
      getOrderCustomerList(this.searchParams).then(response => {
        let data = response
        if (data.code == 200) {
          let _data = data.data
          console.log(data)
          this.priceTotal = _data.total_amount
          this.total = _data.total
          this.listQuery.limit = _data.per_page
          console.log(_data.list)
          this.tableData = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
		
		async _getCustomerFrom() {
      var params = {
        c_type: "from"
			}
      var result = await  getChancelFrom(params)
			console.log(result)
			this.customerFromOptions = result.data
		},
    async _getStaffList () {
      var params = {
        agent_id: this.agentId
      }
      var result = await getStaffList(params)
      console.log(result)
      this.staffOptions = result.data
    },
    selectInit (row, index) {
      // console.log(row)
      if (row.order_status != 9) {
        return false  //不可勾选
      } else {
        return true  //可勾选
      }
    },
    handleOrderStatus (item) {
      console.log(item)
      this.curOrderStatus = item.value;
      this.searchParams.order_status = item.status
      this.getList('restPage')
    },
    handleChangeCustomerStatus(value, prop) {
      // console.log(value[1]); contract_status
      console.log(value[1])
      this.searchParams.contract_status = value[1]
      this.handleSearch('contract_status' ,this.searchParams.contract_status)
			// this.getList('restPage')
		},
    handleSelectionChange (val) {
      this.multipleSelection = []
      this.orderIds = []
      console.log(val)
      this.multipleSelection = val;
      this.multipleSelection.forEach(item => {
        this.orderIds.push(item.order_id);
      })
    },
    async handleBatchCheckOrder () {
      if (this.orderIds.length > 0) {
        var data = {
          order_ids: this.orderIds
        }
        var result = await postBatchCheckOrder(data)
        console.log(result)
        if (result.code == 200) {
          this.$message({
            type: 'success',
            message: '审批成功'
          })
          this.getList("restPage")
        } else {
          this.$message({
            type: 'error',
            message: '审批失败!'
          })
        }
      } else {
        this.$message({
          type: 'error',
          message: '请选择!'
        })
      }
    },
    handleExportOrder () {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      function objToUrl (obj) {
        const tempArray = [];
        Object.keys(obj).forEach(key => {
          tempArray.push(`${key}=${obj[key]}`)
        })
        return tempArray.join('&')
      }

      var date = new Date();
      // 获取当前月份
      var nowMonth = date.getMonth() + 1;
      // 获取当前是几号
      var strDate = date.getDate();
      // 添加分隔符“-”
      var separator = "-";
      // 对月份进行处理，1-9月在前面添加一个“0”
      if (nowMonth >= 1 && nowMonth <= 9) {
        nowMonth = "0" + nowMonth;
      }
      //对月份进行处理，1-9号在前面添加一个“0”
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
    //   var beginDate = date.getFullYear() + separator + date.getMonth() + separator + strDate;
    //   var nowDate = date.getFullYear() + separator + nowMonth + separator + strDate;
      // var str = objToUrl(this.searchParams)
      var params = {
        order_status: this.searchParams.order_status,
        order_sn: this.searchParams.order_sn,
        keywords: this.searchParams.keywords,
        created_at:this.searchParams.created_at,
      }
      postExportOrder(params)
        .then((response) => {
          let data = response;
          loading.close()
          if (data) {
            const xlsx = "application/vnd.ms-excel";
            const blob = new Blob([data], {
              type: xlsx
            });
            const a = document.createElement("a"); // 转换完成，创建一个a标签用于下载
            a.download = "订单管理客情.xlsx";
            a.href = window.URL.createObjectURL(blob);
            a.click();
            a.remove();
          } else {
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          this.dialogVisible3 = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleChangeDate (prop) {
      // this.searchParams.start_at = []
      // this.searchParams.end_at = []
      console.log(this.choiceDate)

      this.searchParams.created_at = ""
      var _tmp0
      var _tmp1
      if (this.choiceDate) {
        _tmp0 = this.choiceDate[0]
        _tmp1 = this.choiceDate[1]
        this.searchParams.created_at += _tmp0 + ',' + _tmp1
      } else {
        // this.searchParams.start_at = []
        // this.searchParams.end_at = []
        this.searchParams.created_at = ""
      }
      // this.getList('restPage')
      this.handleSearch(prop ,this.searchParams.created_at )
    },
    handleChangeDateCreate (prop) {
      // this.searchParams.start_at = []
      // this.searchParams.end_at = []
      console.log(this.choiceDateCreate)

      this.searchParams.create_con_at = ""
      var _tmp0
      var _tmp1
      if (this.choiceDateCreate) {
        _tmp0 = this.choiceDateCreate[0]
        _tmp1 = this.choiceDateCreate[1]
        this.searchParams.create_con_at += _tmp0 + ',' + _tmp1
      } else {
        // this.searchParams.start_at = []
        // this.searchParams.end_at = []
        this.searchParams.create_con_at = ""
      }
      // this.getList('restPage')
      this.handleSearch(prop ,this.searchParams.create_con_at )
    },
		
    handleChangeDateSign(prop) {
      // this.searchParams.start_at = []
      // this.searchParams.end_at = []
      console.log(this.choiceDateSign)

      this.searchParams.sign_at = ""
      var _tmp0
      var _tmp1
      if (this.choiceDateSign) {
        _tmp0 = this.choiceDateSign[0]
        _tmp1 = this.choiceDateSign[1]
        this.searchParams.sign_at += _tmp0 + ',' + _tmp1
      } else {
        // this.searchParams.start_at = []
        // this.searchParams.end_at = []
        this.searchParams.sign_at = ""
      }
      // this.getList('restPage')
      this.handleSearch(prop ,this.searchParams.sign_at )
		},


    handleChangeDateExamine(prop) {
      // this.searchParams.start_at = []
      // this.searchParams.end_at = []
      console.log(this.choiceDateExamine)

      this.searchParams.examine_at = ""
      var _tmp0
      var _tmp1
      if (this.choiceDateExamine) {
        _tmp0 = this.choiceDateExamine[0]
        _tmp1 = this.choiceDateExamine[1]
        this.searchParams.examine_at += _tmp0 + ',' + _tmp1
      } else {
        // this.searchParams.start_at = []
        // this.searchParams.end_at = []
        this.searchParams.examine_at = ""
      }
      // this.getList('restPage')
      this.handleSearch(prop ,this.searchParams.examine_at )
    },

    handleChangeDateSend(prop) {
      // this.searchParams.start_at = []
      // this.searchParams.end_at = []
      console.log(this.choiceDateSend)

      this.searchParams.send_time = ""
      var _tmp0
      var _tmp1
      if (this.choiceDateSend) {
        _tmp0 = this.choiceDateSend[0]
        _tmp1 = this.choiceDateSend[1]
        this.searchParams.send_time += _tmp0 + ',' + _tmp1
      } else {
        // this.searchParams.start_at = []
        // this.searchParams.end_at = []
        this.searchParams.send_time = ""
      }
      // this.getList('restPage')
      this.handleSearch(prop ,this.searchParams.send_time )
    },
		
    handleOrderDetail (order) {
      console.log(order.order_id)
      this.orderId = order.order_id.toString()
      this.order = order
      this.isActiveOrderDetailDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
	/* switch按钮样式 */
	::v-deep.switch {
		.el-switch__label {
			position: absolute;
			display: none;
			color: #fff !important;
		}
		
		/*打开时文字位置设置*/
		.el-switch__label--right {
			z-index: 1;
		}
		
		/* 调整打开时文字的显示位子 */
		.el-switch__label--right span {
			margin-right: 11px;
		}
		
		/*关闭时文字位置设置*/
		.el-switch__label--left {
			z-index: 1;
		}
		
		/* 调整关闭时文字的显示位子 */
		.el-switch__label--left span {
			margin-left: 11px;
		}
		
		/*显示文字*/
		.el-switch__label.is-active {
			display: block;
		}
		
		/* 调整按钮的宽度 */
		.el-switch__core, .el-switch__label {
			width: 55px !important;
			margin: 0;
		}
	}
	
	
	.order-box-first {
		border: 1px solid #cecece;
		padding: 4px 8px;
		// min-width: 50px;
		cursor: pointer;
		font-size: 12px;
	}
	
	.order-box {
		border: 1px solid #cecece;
		padding: 4px 8px;
		// min-width: 60px;
		cursor: pointer;
		border-left: 0;
		font-size: 12px;
	}
	
	.active {
		color: #2632fb;
	}
	
	.isSearch{
		color: #F56C6C;
	}
	.search-icon {
		color: #F56C6C;
	}
</style>
